body {
    width: 100vw;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: Roboto sans-serif;
}

.content {
    margin: 2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 0 20px;
}

.title-container > * {
    margin: 0 20px;
}

.title-container > img {
    height: 5rem;
}

.buttons-container {
    width: fit-content;
    margin: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
}

.buttons-container > div {
    margin: 0.5rem;
    min-width: 100px;
}


.chart {
    width: clamp(200px, 90vw, 150vh);
    height: 60vh;
}

.footer {
    margin: 3rem 0 1rem 0;
}
